* {
  font-family: "Segoe UI";
  line-height: 25px;
}

:root {
  /* --blue: #0a192f; */
  /* --blue: #1a2417; */
  --blue: #000;

  --lightslategray: #8892b0;
  --lavender: #abc8a2;
  --lavender: #fff;
  --aquamarine: #62fbd6;
  /* --slategray: #717c98; */
  --slategray: #914bf1;
  --smallfonts: "Redressed";
}
body {
  background: var(--blue);
}

.my-resume {
  background: #fff;
}

#education,
#experience,
#skills,
#contact,
#youtube,
#projects {
  margin-top: 100px;
}

.navbar {
  background: var(--blue);
}

.navbar .nav-link {
  cursor: pointer;
}

.home-page {
  width: 100%;
  height: calc(100vh - 66px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home-page .home-profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #e0e0e005;
  /* border: 5px solid #e0e0e014; */
  border: 8px solid #ffc73a73;
  display: block;
  padding: 8px;
}

.home-page p {
  margin: 1rem 0;
  font-size: 1.2rem;
  color: var(--lavender);
}

.home-page .myJob {
  font-size: 6vw;
  word-spacing: 10px;
  font-weight: bold;
  margin: 1rem 0;
  color: var(--lavender);
}
.home-page .role {
  font-size: 6vw;
  font-weight: bold;
  margin: 1rem 0;
  color: var(--lavender);
  opacity: 0.2;
  /* text-shadow: 2px 2px 2px #000000; */
}

.contactdetails > div {
  display: flex;
  align-items: center;
}

.heading-top {
  font-weight: bold;
  margin: 2rem 0 3rem;
  font-size: 2rem;
  color: var(--lavender);
}

.skills {
  margin-bottom: 50px;
}

.skills-group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
}

.skill-heading {
  color: var(--lavender);
  font-size: 16px;
  margin: 2rem 0 1rem;
}

.skills .skills-box {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 8px;
  background: #69696930;
  color: var(--lavender);
  border: 0.5px solid #e0e0e014;
}
.skills .text-center {
  color: var(--lightslategray);
}
.wave {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: wave-animation;
  animation-name: wave-animation;
  display: inline-block;
  font-size: 30px;
  -webkit-transform-origin: 70% 70%;
  transform-origin: 70% 70%;
}
.education-system {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.education .education-degree span {
  color: var(--lavender);
  font-size: 18px;
}
.education .education-degree .percentage {
  color: var(--lavender);
  font-size: 18px;
  margin-left: 1rem;
}

.education .college {
  font-size: 16px;
  margin: 4px 0;
}

.education .education-year {
  color: var(--slategray);
  font-size: 14px;
}

.experience-system {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 70vh;
  border: 1px solid var(--lightslategray);
  border-radius: 8px;
  margin: 50px 0;
}

.experience-system2 {
  flex-direction: row-reverse;
}

.experience .experience-details {
  color: var(--lavender);
  padding: 1rem;
  border-radius: 4px;
  background: #69696930;
  border: 0.5px solid #e0e0e014;
  font-size: 18px;
}

.experience .experience-details .role {
  color: var(--slategray);
  font-size: 18px !important;
  font-weight: bold;
}
.experience .experience-details .fw-bold {
  color: var(--lightslategray);
}

.experience .experience-details .company-name {
  font-weight: bold;
}
.experience .experience-details .institute {
  background: var(--lavender);
  font-weight: 500;
  color: var(--blue);
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.contact-form {
  margin-bottom: 3rem;
}

.contact-form .form-control {
  /* background: #3d4c634a;
  color: var(--lightslategray); */
  background: #ffffff12;
  color: #fff;
  border: 0.5px solid #e0e0e014;
}
.contact-form .form-label {
  color: var(--lavender);
  font-weight: bold;
}
.contact-form .btn-send {
  all: unset;
  background: var(--lavender);
  font-weight: bold;
  color: var(--blue);
  padding: 3px 20px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
}
.MuiButtonBase-root {
  outline: none !important;
  border: none;
  color: #fff;
}
.contact-form .detail div:first-child {
  font-weight: bold;
  color: var(--lavender);
}

.contact-form .detail {
  margin: 1rem 0;
  color: var(--lightslategray);
  display: flex;
  align-items: center;
  gap: 1rem;
}
.contact-form .detail .fa {
  width: 50px;
  height: 50px;
  /* background: #abc8a2; */
  background: #e8eafa;
  color: var(--blue);
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
}
.navbar-nav {
  gap: 1rem;
}
.nav-link {
  font-weight: 500;
  padding: 2px 10px !important;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
.nav-link.active {
  background: var(--lavender);
  color: var(--blue) !important;
}
.download-button-new {
  all: unset;
  background: var(--lavender);
  font-weight: 500;
  color: var(--blue);
  padding: 0.5rem;
  border-radius: 3px;
  font-size: 12px;
  margin: 4rem 0;
  cursor: pointer;
}

.download-button-new:hover {
  background: var(--lavender);
  color: var(--blue);
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-brand {
  color: #fff !important;
}
.navbar-brand .profile-img {
  width: 40px;
  height: 40px;
  /* border: 4px solid var(--lavender); */
}
.youtube {
  min-height: 100vh;
}
.projects {
  min-height: 100vh;
}
.projects {
  color: var(--lavender);
}
.projects .project-title {
  color: var(--slategray);
  font-size: 18px !important;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: capitalize;
}
.youtube .video-wrapper {
  position: relative;
  width: 100%; /* Adjust width based on your layout */
  max-width: 560px; /* Optional: Limit max width */
  aspect-ratio: 16 / 9; /* Maintain 16:9 aspect ratio */
}

.youtube .video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  filter: opacity(0.5);
}

.html-blog p {
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.custom-swiper .custom-dot {
  background: var(--lavender);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.3;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.custom-swiper .custom-dot.swiper-pagination-bullet-active {
  /* background: var(--lavender); */
  background: #ff5733;
  opacity: 1;
  transform: scale(1.3);
}
.youtube {
  color: var(--lavender);
}
.youtube .title {
  color: var(--lavender);
  text-transform: capitalize;
}
.bg-lavender {
  background: var(--lavender);
}
.text-lavender {
  color: var(--lavender);
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .education .MuiTimelineItem-root::before {
    display: none;
  }
  .experience .experience-details {
    position: relative;
    padding-bottom: 50px;
  }
  .experience .experience-details .institute {
    position: absolute;
    bottom: 14px;
    right: 14px;
  }
  .education .education-degree span,
  .education .education-degree .percentage {
    font-size: 14px;
  }
  .education .college {
    font-size: 14px;
  }
  .skills-group {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .home-page .role {
    opacity: 0.8;
  }
  .home-page {
    height: calc(100vh - 75px);
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .contact-form .detail .fa {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
  .navbar {
    background: #000;
  }
  .navbar-collapse {
    background: #000;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem;
    border-radius: 0 0 8px 8px;
  }
  #education,
  #experience,
  #skills,
  #contact,
  #youtube,
  #projects {
    margin-top: 100px;
    padding: 0 1rem;
  }
}
